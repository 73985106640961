import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AllPages from './AllPages';
import reportWebVitals from './reportWebVitals';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

/*const router = createHashRouter([
  {
      path:"/*",
      element:<AllPages></AllPages>,
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
      <RouterProvider router={router}></RouterProvider>
  </React.StrictMode> 
)*/

ReactDOM.render(
  <BrowserRouter>
      <AllPages />
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
