import '../App.css';
import React, { useEffect } from 'react'

export default function Projects() {
    useEffect(() =>{
        document.title = "Projetos"
    }, [])
    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className='list-style'>
                                <li className='list-style-item'>
                                    <a href="/" className='list-style-item-inner nohyperlink' style={{marginLeft: "-10px"}}>
                                        About me
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner special-link'>
                                        Projects
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">Blog/Reddit Website and Mobile App</h2>
                            <p>
                                This project part of a class called Integrated Project. It was proposed by a local tech company to challenge students to improve their web, backend, and mobile programming skills.
                                <br />
                                The project consists of a website for admin usage that includes a backoffice for management, a mobile application (Android) for users, and a database to store all data.
                            </p>
                            <div className="image-gallery">
                                <div className="image-item">
                                    <a style={{cursor: "pointer"}} href="Project1/Project1-1.png" target="_blank">
                                        <img src='./Project1/Project1-1.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item">
                                    <a style={{cursor: "pointer"}} href="Project1/Project1-2.png" target="_blank">
                                        <img src='./Project1/Project1-2.png' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                                <div className="image-item">
                                    <a style={{cursor: "pointer"}} href="Project1/Project1-3.png" target="_blank">
                                        <img src='./Project1/Project1-3.png' className="responsive-image" alt="Project Screenshot 3"/>
                                    </a>
                                </div>
                            </div>
                            <div style={{height: "30px"}}>

                            </div>
                            <div className="image-gallery">
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="Project1/Project1-4.jpg" target="_blank">
                                        <img src='./Project1/Project1-4.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="Project1/Project1-5.jpg" target="_blank">
                                        <img src='./Project1/Project1-5.jpg' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="Project1/Project1-6.jpg" target="_blank">
                                        <img src='./Project1/Project1-6.jpg' className="responsive-image" alt="Project Screenshot 3"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">Arduino Temperature, Humidity and Light Project</h2>
                            <p>
                                This project was made by me to learn and improve my skills on embedded systems.
                                <br />
                                It consists of:
                                <ul>
                                    <li>
                                        1 Arduino
                                    </li>
                                    <li>
                                        3x 74HC595 8 bit shift registers
                                    </li>
                                    <li>
                                        10x Yellow LEDs
                                    </li>
                                    <li>
                                        1x DHT11 Temperature and Humidity Sensor
                                    </li>
                                    <li>
                                        1x Potenciometer
                                    </li>
                                    <li>
                                        1x 4 Digit 7 Segment Display
                                    </li>
                                </ul>
                                This small and fun project allowed me to learn how:
                                <br></br>
                                <ul>
                                    <li>
                                        To use the 74HC595 8 bit shift register, that allows us to get 8 digital output pins while only using 3 pins on the arduino.
                                    </li>
                                    <li>
                                        Daisy chaining two shift registers work to create 16 output pins while still only using 3 pins from the arduino.
                                    </li>
                                    <li>
                                        To read data from the DHT11 Temperature and Humidity sensor.
                                    </li>
                                    <li>
                                        To manage the 8 output pins from the shift register and make "christmas tree" light festival using the 10 leds.
                                    </li>
                                    <li>
                                        To, using the 2 shift registers, display the correct data, such as temperature and humidity on the display
                                        <br></br>
                                        As can be seen on the third photo, the 24 is the temperature in celsius and the 61 is the humidity in percentage.
                                    </li>
                                    <li>
                                        A potenciometer can be used to switch between modes and displaying different things on the display or lights.
                                    </li>
                                </ul>
                            </p>
                            <div className="image-gallery">
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="Project2/Project2-1.jpg" target="_blank">
                                        <img src='./Project2/Project2-1.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="Project2/Project2-3.jpg" target="_blank">
                                        <img src='./Project2/Project2-3.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="Project2/Project2-2.jpg" target="_blank">
                                        <img src='./Project2/Project2-2.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">A Web Car Museum</h2>
                            <p>
                                During the class Internet Applications I, on the first semester of the second year, we, students, had to develop a website using plain HTML, CSS and JavaScript.
                                As such, me and my group decided to opt for a showcase of cars on the internet. 
                            </p>
                            <p>
                                The website includes:
                                <ul>
                                    <li>
                                        A slideshow at the top of the page containing the logos of almost 20 different brands of cars.
                                    </li>
                                    <li>
                                        Four categories of cars:
                                        <ul>
                                            <li>
                                                Classics
                                            </li>
                                            <li>
                                                Sports
                                            </li>
                                            <li>
                                                Super
                                            </li>
                                            <li>    
                                                4x4 / All terrain
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Each unique category contains 5 different cars specifically chosen by the members of the group
                                    </li>
                                    <li>
                                        A dark mode feature that remains even if the page is refreshed or closed.
                                    </li>
                                    <li>
                                        An extra page, as an easter egg, that contains one special vehicle by each member of the group, as seen on the third image.
                                    </li>
                                </ul>
                            </p>
                            <div className="image-gallery">
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="Project3/Project3-1.png" target="_blank">
                                        <img src='./Project3/Project3-1.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="Project3/Project3-2.png" target="_blank">
                                        <img src='./Project3/Project3-2.png' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                            </div>
                            <div style={{height: "30px"}}>

                            </div>
                            <div className="image-gallery">
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="Project3/Project3-3.png" target="_blank">
                                        <img src='./Project3/Project3-3.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="Project3/Project3-4.png" target="_blank">
                                        <img src='./Project3/Project3-4.png' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">SPIFF (In Development)</h2>
                        </div>
                        <p>
                            Sistema de Proteção e Prevenção de Incêndios Florestais (<span className='special-link'>Forest Fire Protection and Prevention System</span>) is a work in progress project being developed on the class Embedded Systems.
                            <br></br>
                            This system, as the name refers, consists of a group of devices/sensors to help detect and prevent forest fires. They are:
                            <ul>
                                <li>
                                    1x Raspberry Pi - To wireless receive data and store it on a database.
                                </li>
                                <li>
                                    1x Raspberry Pi Camera - To record the forest and with an algorithm/AI detect smoke or fire.
                                </li>
                                <li>
                                    1x ESP8266 - To read the data from the sensors and send it to the Raspberry Pi
                                </li>
                                <li>
                                    1x Arduino UNO - To configure and code the ESP8266
                                </li>
                                <li>
                                    1x DHT11 - To read the temperature and humidity
                                </li>
                                <li>
                                    1x MQ-135 - To read the amount of PPM in the air of carbon monoxide.
                                </li>
                            </ul>
                            <p>
                                All these systems combined will be a great way to monitor a specific zone and help prevent desasters like wild fires.
                            </p>
                            <p>
                                More information about this project will be added as development continues.
                            </p>
                        </p>
                    </div>
                </div>
            </section>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">And more to be developed...</h2>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
