import '../App.css';
import React, { useEffect } from 'react';

let hotw = [
    {"id": 1, "name":"i am something1", "color": "red"},
    {"id": 2, "name":"i am something123", "color": "green"},
    {"id": 3, "name":"i am something321", "color": "yellow"},
    {"id": 4, "name":"i am something455556", "color": "blue"},
];

export default function HotWheels() {
    useEffect(() => {
        document.title = "Hot Wheels";
    }, []);

    return (
        <div className="row d-flex flex-wrap">
            <CarCard />
        </div>
    );

    function CarCard(){
        return hotw.map((hw) => (
            <div className="col-12 col-md-6 col-lg-4 car-card">
                a
            </div>
        ));
    }

    function BuildEachCar() {
        return hotw.map((hw) => (
            <li key={hw.id}>
                <h4>{hw.name}</h4>
                <span>{hw.id}</span>
                <span>{hw.color}</span>
            </li>
        ));
    }
}
